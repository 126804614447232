import React from "react";
import "./signs.css";
import BigSign from "./BigSign";
import SmallSign from "./SmallSign";

const Sign = ({ sizeProp }) => {
  if (sizeProp === "big") {
    return <BigSign />;
  } else return <SmallSign />;
};

export default Sign;
