import React, { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import BigAlergen from "./BigAlergen";
import SmallAlergen from "./SmallAlergen";
import "./alergenList.css";

const AlergenList = ({ size }) => {
  const { sign } = useContext(AppContext);

  const alergens = sign.alergens.filter((alergen) => alergen.contains);

  if (alergens.length === 0) {
    return <p className={size === "big" ? 'bigNoAlergens' : 'smallNoAlergens'}>No contiene alergenos</p>;
  }

  if (size === "big") {
    return (
      <div id="alergen-list">
        {alergens.map((alergen) => (
          <BigAlergen key={alergen.name} alergen={alergen} />
        ))}
      </div>
    );
  } else {
    return (
      <div id="alergen-list">
        {alergens.map((alergen) => (
          <SmallAlergen key={alergen.name} alergen={alergen} />
        ))}
      </div>
    );
  }
};

export default AlergenList;
