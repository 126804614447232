import gluten from "./img/gluten.svg";
import huevos from "./img/huevos.svg";
import lacteos from "./img/lacteos.svg";
import pescado from "./img/pescado.svg";
import cascara from "./img/cascara.svg";
import crustaceos from "./img/crustaceos.svg";
import soja from "./img/soja.svg";
import moluscos from "./img/moluscos.svg";
import cacahuetes from "./img/cacahuete.svg";
import sesamo from "./img/sesamo.svg";
import sulfitos from "./img/sulfitos.svg";
import mostaza from "./img/mostaza.svg";
import apio from "./img/apio.svg";
import altramuces from "./img/altramuces.svg";

export const alergens = [
  {
    name: "Gluten",
    contains: true,
    img: gluten,
  },
  {
    name: "Huevo",
    contains: false,
    img: huevos,
  },
  {
    name: "Pescado",
    contains: false,
    img: pescado,
  },
  {
    name: "Lácteos",
    contains: false,
    img: lacteos,
  },
  {
    name: "Soja",
    contains: true,
    img: soja,
  },
  {
    name: "Frutos de cáscara",
    contains: false,
    img: cascara,
  },
  {
    name: "Crustáceos",
    contains: false,
    img: crustaceos,
  },
  {
    name: "Moluscos",
    contains: false,
    img: moluscos,
  },
  {
    name: "Sésamo",
    contains: true,
    img: sesamo,
  },
  {
    name: "Sulfitos",
    contains: false,
    img: sulfitos,
  },
  {
    name: "Mostaza",
    contains: false,
    img: mostaza,
  },
  {
    name: "Cacahuetes",
    contains: false,
    img: cacahuetes,
  },
  {
    name: "Apio",
    contains: false,
    img: apio,
  },
  {
    name: "Altramuces",
    contains: false,
    img: altramuces,
  },
];

export const sign = {
  name: "Pollo asado",
  price: "7.50",
  alergens,
};

export const fontSizes = {
  bigName: 3.0,
  bigPrice: 2.0,
  smallName: 2.0,
  smallPrice: 2.0,
};
