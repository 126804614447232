import { AppContext } from "./contexts/AppContext";
import { EditorContext } from "./contexts/EditorContext";
import React, { useState } from "react";
import { sign as signModel, fontSizes as fontSizesModel } from "./models";
import Sign from "./components/Sign/Sign";
import SignEditor from "./components/SignEditor/SignEditor";
import "./App.css";

function App() {
  const [sign, setSign] = useState(signModel);
  const [fontSizes, setFontSizes] = useState(fontSizesModel);

  const changeAlergen = (e) => {
    let copySign = { ...sign };
    copySign.alergens.forEach((alergen) => {
      if (alergen.name === e.target.value) alergen.contains = e.target.checked;
    });
    setSign(copySign);
  };

  const changeSignName = (e) => {
    setSign(Object.assign({}, sign, { name: e.target.value }));
  };

  const changeSignPrice = (e) => {
    setSign(Object.assign({}, sign, { price: e.target.value }));
  };

  const incrementBigName = (e) => {
    e.preventDefault();
    setFontSizes(
      Object.assign({}, fontSizes, { bigName: fontSizes.bigName + 0.1 })
    );
  };

  const decrementBigName = (e) => {
    e.preventDefault();
    setFontSizes(
      Object.assign({}, fontSizes, { bigName: fontSizes.bigName - 0.1 })
    );
  };

  const incrementBigPrice = (e) => {
    e.preventDefault();
    setFontSizes(
      Object.assign({}, fontSizes, { bigPrice: fontSizes.bigPrice + 0.1 })
    );
  };

  const decrementBigPrice = (e) => {
    e.preventDefault();
    setFontSizes(
      Object.assign({}, fontSizes, { bigPrice: fontSizes.bigPrice - 0.1 })
    );
  };

  const incrementSmallName = (e) => {
    e.preventDefault();
    setFontSizes(
      Object.assign({}, fontSizes, { smallName: fontSizes.smallName + 0.1 })
    );
  };

  const decrementSmallName = (e) => {
    e.preventDefault();
    setFontSizes(
      Object.assign({}, fontSizes, { smallName: fontSizes.smallName - 0.1 })
    );
  };

  const incrementSmallPrice = (e) => {
    e.preventDefault();
    setFontSizes(
      Object.assign({}, fontSizes, { smallPrice: fontSizes.smallPrice + 0.1 })
    );
  };

  const decrementSmallPrice = (e) => {
    e.preventDefault();
    setFontSizes(
      Object.assign({}, fontSizes, { smallPrice: fontSizes.smallPrice - 0.1 })
    );
  };

  return (
    <div className="App">
      <AppContext.Provider
        value={{
          sign,
          fontSizes,
        }}
      >
        <Sign sizeProp="big" />
        <Sign sizeProp="small" />
        <EditorContext.Provider
          value={{
            changeSignName,
            changeSignPrice,
            changeAlergen,
            incrementBigName,
            decrementBigPrice,
            incrementBigPrice,
            decrementBigName,
            incrementSmallName,
            incrementSmallPrice,
            decrementSmallName,
            decrementSmallPrice,
          }}
        >
          <SignEditor />
        </EditorContext.Provider>
      </AppContext.Provider>
    </div>
  );
}

export default App;
