import React from "react";

const CheckboxForm = ({alergen, handleOnChange}) => {


  return (
    <div className="multipleInput">
      <label key={alergen.name}>
        <input
          type="checkbox"
          checked={alergen.contains}
          value={alergen.name}
          onChange={handleOnChange}
        ></input>{" "}
        {alergen.name}
      </label>
    </div>
  );
};

export default CheckboxForm;
