import React from "react";

const SmallAlergen = ({ alergen }) => {
  return (
    <img
      className="smallAlergen"
      key={alergen.name}
      src={alergen.img}
      alt={alergen.name}
    ></img>
  );
};

export default SmallAlergen;
