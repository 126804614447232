import React from "react";

const BigAlergen = ({ alergen }) => {
  return (
    <img
      className="bigAlergen"
      key={alergen.name}
      src={alergen.img}
      alt={alergen.name}
    ></img>
  );
};

export default BigAlergen;
