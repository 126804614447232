import React, { useContext } from "react";
import "./signs.css";
import { AppContext } from "../../contexts/AppContext";
import AlergenList from "./AlergenList";

const BigSign = () => {
  const { sign, fontSizes } = useContext(AppContext);
  return (
    <main>
      <div id="bigCard" className="card">
        <div id="bigCardName">
          <p style={{ fontSize: `${fontSizes.bigName}cm` }}>{sign.name}</p>
        </div>
        <div id="bigCardAlergens">
          <AlergenList size="big" />
        </div>
        <div id="bigCardPrice">
          <p style={{ fontSize: `${fontSizes.bigPrice}cm` }}>{sign.price}</p>
        </div>
        <div id="bigSeparator"></div>
      </div>
    </main>
  );
};

export default BigSign;
