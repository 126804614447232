import React, { useContext } from "react";
import domtoimage from "dom-to-image-more";
import FileSaver from "file-saver";
import { Button } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { AppContext } from "../../contexts/AppContext";

const DownloadButton = ({ elementId, value }) => {
  const { sign } = useContext(AppContext);
  const handleOnClick = (e) => {
    e.preventDefault();
    domtoimage.toBlob(document.getElementById(elementId)).then((blob) => {
      FileSaver.saveAs(blob, sign.name);
    });
  };
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<GetApp />}
      onClick={handleOnClick}
    >
      {value}
    </Button>
  );
};

export default DownloadButton;
