import React, { useContext } from "react";
import "./signs.css";
import { AppContext } from "../../contexts/AppContext";
import AlergenList from "./AlergenList";

const SmallSign = () => {
  const { sign, fontSizes } = useContext(AppContext);

  return (
    <main>
      <div id="smallCard" className="card">
        <div id="smallCardName">
          <p style={{fontSize: `${fontSizes.smallName}cm`}}>{sign.name}</p>
        </div>
        <div id="smallCardAlergens">
          <AlergenList size="small" />
        </div>
        <div id="smallCardPrice">
          <p style={{fontSize: `${fontSizes.smallPrice}cm`}}>{sign.price}</p>
        </div>
        <div id="smallSeparator"></div>
      </div>
    </main>
  );
};

export default SmallSign;
